import { useTranslation } from 'next-i18next'
import Router from 'next/router'
import React, { useEffect } from 'react'
import { useFilterProvider } from 'src/context/FilterProvider'
import useLogger from 'src/context/LoggerProvider/useLogger'
import useBreakpoints from 'src/hooks/useBreakpoints'
import useCommon from 'src/hooks/useCommon'
import { RedirectUrls } from 'src/types/types'
import { getCoverPhotoUrl } from 'src/utils/coverPhoto'
import { ILocation } from '../../types/graphql'
import { decimalsWhenNeeded, numberRounedUpWithoutDecimals } from '../../utils'
import CardLocationLink from '../CardLinks/CardLocationLink'
import ImageWithFallback from '../ImageWithFallback'

interface SpotCardProps {
  location: ILocation
  styleDefault?: boolean
  toggleLocation?: (location: ILocation | undefined) => void
}

const SpotCard = ({
  location,
  styleDefault = true,
  toggleLocation
}: SpotCardProps) => {
  const { t } = useTranslation('common')
  const { isUpMd, isDownMd } = useBreakpoints()
  const { urlRoute, title, price, size, shortAddress, discountEnabled } =
    location
  const { locMap } = useCommon()
  const locType = locMap(location)
  const logger = useLogger()

  const toggle = toggleLocation && isUpMd ? toggleLocation : () => {}

  const { filters, shallowParams, shallowUpdateParams, isReady, isSliderOpen } =
    useFilterProvider()

  const getUrl = (args?: { [key: string]: string }) => {
    const entries = Object.entries({
      ...shallowParams,
      ...filters,
      ...args,
      sliderOpen: isSliderOpen
    }).filter(([, v]) => v !== undefined && v !== null)
    const params = new URLSearchParams(entries as string[][])
    const url = isUpMd ? (urlRoute ?? '') : `${urlRoute}?${params.toString()}`
    return url
  }

  const [isClicked, setIsClicked] = React.useState(false)
  const onClick = () => {
    if (isUpMd) return
    shallowUpdateParams({ scrollY: location.id })
    const url = getUrl({ scrollY: location.id })
    Router.push(`${RedirectUrls.Details}/${url}`)
    setIsClicked(true)
  }

  const length = decimalsWhenNeeded(size?.length ?? 0)
  const width = decimalsWhenNeeded(size?.width ?? 0)
  const url = getUrl()

  const discount = (
    <div className="rounded-lg bg-green-200 px-2 py-1 text-center text-xs font-medium leading-none text-green-800">
      50% off <br />
      <span className="text-[11px]">1er mes</span>
    </div>
  )

  const ref = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!isReady || !isDownMd) return
    if (!isSliderOpen) return
    if (!ref.current) return
    if (isClicked) return

    const isInViewWindow = ref.current.getBoundingClientRect().top < 0
    if (isInViewWindow) return

    if (shallowParams.scrollY === location.id) {
      ref.current.scrollIntoView({ behavior: 'instant', block: 'center' })
    }
  }, [isClicked, isDownMd, isReady, location.id, shallowParams, isSliderOpen])

  return (
    <div
      ref={ref}
      className={`${styleDefault ? 'location-search-card' : 'location-search-card min-w-64 max-w-64 md:min-w-80 md:max-w-80'} relative flex flex-col rounded-xl shadow-lg`}
      onMouseEnter={() => toggle(location)}
      onMouseLeave={() => toggle(undefined)}
    >
      <CardLocationLink
        urlRoute={url}
        onClick={onClick}
        // eslint-disable-next-line react/no-children-prop
        children="View location details"
      />
      <div
        className={`relative w-full overflow-hidden rounded-t-2xl ${styleDefault ? 'aspect-[2/1] md:aspect-[100/66]' : 'aspect-[130/87] md:aspect-[160/87]'}`}
        key={title}
      >
        <ImageWithFallback
          src={getCoverPhotoUrl(location)}
          alt="service"
          fill
          sizes="(max-width: 428px) 45vw,
          (max-width: 768px) 45vw,
          (max-width: 1200px) 18vw,
          18vw"
          style={{ objectFit: 'cover' }}
          onError={(e) => logger.error('Error loading img', e)}
        />
      </div>
      <div className="whitespace-nowrap px-3 py-1 text-black-400">
        <div className="flex w-full flex-row text-lg font-semibold md:text-base">
          <p className="overflow-hidden text-ellipsis">
            {locType ? `${locType}` : ''}
          </p>
          <div className="ml-auto">
            <p>
              {length} x {width}
            </p>
          </div>
        </div>

        <p className="truncate text-sm text-black-400 md:text-xs">
          {shortAddress}
        </p>

        <div className="mt-1 flex flex-row justify-between pb-[4px]">
          <p className="text-lg font-bold text-blue-500 md:text-base">
            ${numberRounedUpWithoutDecimals(price)} /{t('FILTER.MONTH')}
          </p>

          <div className="-mt-1">{discountEnabled && discount}</div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SpotCard)
