import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { LOCATION_TYPE_MAP } from 'src/constants'
import {
  ILocation,
  InputMaybe,
  LocationType,
  Maybe,
  SizeFilter
} from 'src/types/graphql'
import { mapCanStoreVehicleToRadio } from 'src/utils'

type IMap = { [key in LocationType]?: { label: string; icon: string } }

const EXCLUDED = [
  LocationType.Warehouse,
  LocationType.MiniWinery,
  LocationType.Shed,
  LocationType.House,
  LocationType.Yard,
  LocationType.Attic,
  LocationType.Basement,
  LocationType.Penthouse
]

const UNTOUCHED: { [key in LocationType]?: LocationType } = {
  [LocationType.BodegaIndustrial]: LocationType.BodegaIndustrial,
  [LocationType.NaveIndustrial]: LocationType.NaveIndustrial
}

export default function useCommon() {
  const { t } = useTranslation('common')
  const locMap = t('LOCATION_TYPE', { returnObjects: true })
  const vehicleMap = t('CAN_STORE_VEHICLE', { returnObjects: true })
  const sizeMap = t('SIZE_FILTER', { returnObjects: true })

  const filteredMap = useMemo(
    () =>
      (Object.entries(locMap) as [keyof typeof locMap, string][])
        .filter(([key]) => !EXCLUDED.includes(key as LocationType))
        .reduce((memo: IMap, [key, value]) => {
          memo[key] = {
            label: value,
            icon: LOCATION_TYPE_MAP[key]?.icon ?? ''
          }
          return memo
        }, {}),
    [locMap]
  )

  const locMapValues = useMemo(
    () =>
      Object.entries(filteredMap).reduce(
        (memo: { [key: string]: string }, [key, value]) => {
          memo[key] = value.label
          return memo
        },
        {}
      ),
    [filteredMap]
  )

  const value = useMemo(() => {
    return {
      filteredMap,
      locMapValues,
      locMap: (location: Maybe<ILocation> | undefined) => {
        if (!location) return ''
        const { canStoreVehicle, size, locationType } = location
        const untouched = locationType ? UNTOUCHED[locationType] : undefined
        if (untouched) return locMap[untouched]
        const sqm = (size?.length ?? 1) * (size?.width ?? 1)
        const simplified = canStoreVehicle
          ? LocationType.Parking
          : sqm > 15
            ? LocationType.Warehouse
            : LocationType.SelfStorageUnit
        return locMap[simplified]
      },
      nonSimplifiedLocMap: (locationType: Maybe<LocationType> | undefined) =>
        locationType ? locMap[locationType] : '',

      vehicleMapValues: vehicleMap,
      vehicleMap: (canStoreVehicle: InputMaybe<boolean> | undefined) =>
        vehicleMap[mapCanStoreVehicleToRadio(canStoreVehicle)],

      sizeMapValues: sizeMap,
      sizeMap: (size: Maybe<SizeFilter> | undefined) =>
        size ? sizeMap[size] : ''
    }
  }, [filteredMap, locMapValues, vehicleMap, sizeMap, locMap])

  return value
}
