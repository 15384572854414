import Router, { useRouter } from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { DEFAULT_ZOOM } from '../constants'
import { SizeFilter, SortBy } from '../types/graphql'
import { IQueryParams, RedirectUrls } from '../types/types'

type IShallowOptional = {
  lat?: number
  lng?: number
  zoom?: number
  placeId?: string | undefined
  page?: number | undefined
}

type IShallowParams = {
  sliderOpen: boolean
  scrollY: string | undefined
}

type IFilterContext = {
  isReady: boolean
  filters: IQueryParams
  updateParams: (args: Partial<IQueryParams>) => void
  filtersOpen: boolean
  setFiltersOpen: (open: boolean) => void
  shallowParams: IShallowParams
  shallowUpdateParams: (
    args: Partial<IShallowParams & IShallowOptional>
  ) => void
  isSliderOpen: boolean
  setIsSliderOpen: (open: boolean) => void
}

export const FilterContext = createContext<IFilterContext>({
  isReady: false,
  filters: {
    search: undefined,
    placeId: undefined,
    description: undefined,
    lat: undefined,
    lng: undefined,
    zoom: undefined,
    sortBy: undefined,
    minPrice: 0,
    maxPrice: 0,
    canStoreVehicle: undefined,
    size: undefined,
    page: 1,
    staticPage: undefined
  },
  updateParams: () => {},
  filtersOpen: false,
  setFiltersOpen: () => {},
  shallowParams: { sliderOpen: false, scrollY: undefined },
  shallowUpdateParams: () => {},
  isSliderOpen: false,
  setIsSliderOpen: () => {}
})

const FilterProvider: React.FC<{
  children: React.ReactNode
  initialFilters?: Partial<IQueryParams>
}> = ({ children, initialFilters }) => {
  const value = useFilters(initialFilters)

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

export default React.memo(FilterProvider)

export const useFilterProvider = (): IFilterContext => useContext(FilterContext)

/**
 * Hook
 */

const useFilters = (initialFilters?: Partial<IQueryParams>): IFilterContext => {
  const router = useRouter()

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)

  const filters = useMemo<IQueryParams>(() => {
    let { lat, lng, zoom } = router.query
    const {
      search,
      placeId,
      description,
      sortBy,
      canStoreVehicle,
      size,
      maxPrice,
      minPrice,
      page,
      staticPage
    } = router.query

    if (typeof window !== 'undefined' && !initialFilters && !router.isReady) {
      const urlParams = new URLSearchParams(window.location.search)
      lat =
        typeof urlParams.get('lat') === 'string'
          ? (urlParams.get('lat') as string)
          : undefined
      lng =
        typeof urlParams.get('lng') === 'string'
          ? (urlParams.get('lng') as string)
          : undefined
      zoom =
        typeof urlParams.get('zoom') === 'string'
          ? (urlParams.get('zoom') as string)
          : undefined
    }

    return {
      search: toStr(search),
      placeId: toStr(placeId),
      description: toStr(description),
      lat: toNum(lat),
      lng: toNum(lng),
      zoom: toNum(zoom) ?? DEFAULT_ZOOM,
      sortBy: toEnum<SortBy>(sortBy),
      maxPrice: toNum(maxPrice),
      minPrice: toNum(minPrice),
      canStoreVehicle: toBool(canStoreVehicle),
      size: toEnum<SizeFilter>(size),
      page: toNum(page) ?? 1,
      staticPage: toBool(staticPage),
      ...initialFilters
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, initialFilters])

  const shallowParams = useMemo<IShallowParams>(() => {
    let { sliderOpen, scrollY } = router.query
    if (typeof window !== 'undefined' && !initialFilters && !router.isReady) {
      const urlParams = new URLSearchParams(window.location.search)
      sliderOpen =
        typeof urlParams.get('sliderOpen') === 'string'
          ? (urlParams.get('sliderOpen') as string)
          : undefined
      scrollY =
        typeof urlParams.get('scrollY') === 'string'
          ? (urlParams.get('scrollY') as string)
          : undefined
    }
    return {
      sliderOpen: toBool(sliderOpen) ?? !!initialFilters,
      scrollY: toStr(scrollY) ?? undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, initialFilters])

  const shallowUpdateParams = useCallback(
    (params: Partial<IShallowParams & IShallowOptional>): void => {
      const newParams = {
        ...filters,
        ...shallowParams,
        ...initialFilters,
        ...params
      }
      const filteredParams = getFilteredParams(newParams)

      Router.push(
        {
          pathname: RedirectUrls.Search,
          query: filteredParams
        },
        undefined,
        { shallow: true }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.query]
  )

  const updateParams = useCallback(
    (params: Partial<IQueryParams>): void => {
      const newParams = {
        ...filters,
        ...shallowParams,
        ...initialFilters,
        ...params
      }
      const filteredParams = getFilteredParams(newParams)

      Router.push({
        pathname: RedirectUrls.Search,
        query: filteredParams
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.query, filters, initialFilters]
  )

  const [isSliderOpen, setIsSliderOpen] = useState(shallowParams.sliderOpen)

  const value = useMemo(
    (): IFilterContext => ({
      isReady: router.isReady,
      filters,
      updateParams,
      filtersOpen,
      setFiltersOpen,
      shallowParams,
      shallowUpdateParams,
      isSliderOpen,
      setIsSliderOpen
    }),
    [
      router.isReady,
      filters,
      updateParams,
      filtersOpen,
      shallowParams,
      shallowUpdateParams,
      isSliderOpen,
      setIsSliderOpen
    ]
  )

  return value
}

/**
 * Helpers
 */

function toStr(arg: string | string[] | undefined): string | undefined {
  if (typeof arg === 'string') return arg
  return undefined
}

function toEnum<T>(arg: string | string[] | undefined): T | undefined {
  if (typeof arg === 'string') return arg as unknown as T
  return undefined
}

function toNum(arg: string | string[] | undefined): number | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (typeof arg === 'string' && !isNaN(arg as any)) return Number(arg)
  return undefined
}

function toBool(arg: string | string[] | undefined): boolean | undefined {
  if (typeof arg === 'string') {
    return arg === 'true' ? true : arg === 'false' ? false : undefined
  }
  return undefined
}

function getFilteredParams(params: Partial<IQueryParams>) {
  return Object.entries(params).reduce(
    (
      memo: { [key: string]: string | number | [number, number] | boolean },
      [key, value]
    ) => {
      if (value !== undefined && value !== null && value !== '') {
        memo[key] = value
      }

      return memo
    },
    {}
  )
}
