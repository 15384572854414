import Image, { ImageProps } from 'next/image'
import React, { useEffect, useState } from 'react'
import useLogger from 'src/context/LoggerProvider/useLogger'

const EMPTY = '/images/empty.jpeg'
const AVATAR = '/images/avatar.png'

interface ImageWithFallbackProps extends ImageProps {
  fallbackSrc?: string
  defaultAvatar?: boolean
}

const ImageWithFallback = (props: ImageWithFallbackProps) => {
  const { src, fallbackSrc, defaultAvatar, ...rest } = props
  const logger = useLogger()
  const [imgSrc, setImgSrc] = useState(src)

  const handleOnError = () => {
    const fallback = fallbackSrc ?? (defaultAvatar ? AVATAR : EMPTY)
    logger.warn(`Failed to load image`, { src })
    setImgSrc(fallback)
  }

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image {...rest} src={imgSrc} onError={handleOnError} />
}

export default React.memo(ImageWithFallback)
